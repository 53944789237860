// import _ from 'lodash';
import moment from 'moment';
import React from 'react';
const columns = {};

columns.id = {
  title: 'ID',
  width: 180,
  dataIndex: 'objectId',
  key: 'objectId'
  //sorter: true,
  //sortOrder: _.get(search, 'orderBy') === '_id' && _.get(search, 'order')
};
columns.createdAt = {
  title: 'Date',
  width: 200,
  dataIndex: '_created_at',
  render: (record) => moment(record).format('HH:mm YYYY-MM-DD'),
  key: 'createdAt',
  sorter: true
  // sortOrder:
  //   _.get(search, 'orderBy') === '_created_at' && _.get(search, 'order')
};
columns.name = {
  title: '姓名',
  dataIndex: 'fullName',
  key: 'fullName',
  render: (record) => (<p className="name-field">{record}</p>),
  width: 160,
  sorter: true
};
columns.phone = {
  title: '電話番号',
  dataIndex: 'phone',
  key: 'phone',
  width: 160,
  sorter: true
};
columns.email = {
  title: 'メールアドレス',
  dataIndex: 'email',
  key: 'email',
  width: 250,
  sorter: true
};
columns.question = {
  title: 'Question',
  dataIndex: 'question',
  key: 'question',
  render: (record) => (<p className="question-field">{record}</p>),
  width: 280,
  sorter: true
};

columns.salonName = {
  title: '会社 / 店舗名',
  dataIndex: 'salonName',
  key: 'salonName',
  width: 200
};
columns.serviceRole = {
  title: 'カテゴリ',
  dataIndex: 'serviceRole',
  key: 'serviceRole',
  width: 200,
  render: (serviceRole) => {
    switch (serviceRole) {
      case 'EYELIST':
        return 'アイラッシュ/アイブロウ';
      case 'BOTH':
        return '両方';
      default:
        return 'ネイル';
    }
  }
};
columns.deviceInfo = {
  title: 'deviceInfo',
  width: 120,
  dataIndex: 'deviceInfo',
  key: 'deviceInfo'
};
columns.subType = {
  title: 'お問い合わせ内容',
  width: 200,
  dataIndex: 'subType',
  key: 'subType',
  render: (record) => {
    if (record === 'INFORMATION')
      return 'サロンマネージャーの詳細を知りたい';
    if (record === 'REQUEST')
      return 'サロンマネージャーを利用申請したい';
    return record;
  },
  sorter: true
};
columns.url = {
  title: 'サロンのURL',
  width: 120,
  dataIndex: 'salonURL',
  key: 'salonUrl'
};
columns.staffCount = {
  title: 'スタッフ数',
  width: 120,
  dataIndex: 'staffCount',
  key: 'staffCount'
};
export default columns;