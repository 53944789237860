import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageHeader from 'components/PageHeader';

import SalonPayout from './Salon';
import NailistPayout from './Nailist';

const RedirectToNailistPayout = () => (
  <Redirect to="/dashboard/payout/payout-nailist" />
);

const Router = () => {
  return (
    <>
      <Helmet>
        <title>Nailie Dashboard | Payout | Search</title>
      </Helmet>
      <PageHeader
        title="Payout search"
        breadcrumb={PageHeader.Bread.payoutSearch}
      />
      <Switch>
        <Route
          path="/dashboard/payout/payout-nailist"
          exact
          render={(props) => <NailistPayout {...props} serviceRole="NAILIST" />}
        />
        <Route
          path="/dashboard/payout/payout-eyelist"
          exact
          render={(props) => <NailistPayout {...props} serviceRole="EYELIST" />}
        />
        <Route
          path="/dashboard/payout/payout-salon"
          exact
          component={SalonPayout}
        />
        <Route path="/dashboard/payout" component={RedirectToNailistPayout} />
      </Switch>
    </>
  );
};

export default Router;
