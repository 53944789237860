import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Table, Card, Spin } from 'antd';

import PageHeader from 'components/PageHeader';
import './styles.scss';

import useTableControl from '../Preset/useNailistTableControl';
import FilterForm from '../../components/FilterForm';

import columnsTemplate, { defaultOrder } from '../Preset/columnsTemplates';

const NewSearchNailistPage = ({ list, total, fetchNailistList }) => {
  const [search, handleTableChange, handleSubmitFilterForm] = useTableControl(defaultOrder);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const columns = [
    columnsTemplate.id,
    { ...columnsTemplate.createdAt, defaultSortOrder: columnsTemplate.createdAt.key === search.orderBy ? search.order : undefined },
    columnsTemplate.fullName,
    columnsTemplate.username,
    columnsTemplate.role,
    columnsTemplate.salonName,
    columnsTemplate.status,
    columnsTemplate.email,
    columnsTemplate.phone,
    columnsTemplate.action
  ];

  useEffect(() => {
    (async() => {
      setLoading(true);
      await fetchNailistList(search);
      setLoading(false);
      setLoaded(true);
    })();
  }, [search]);

  return (
    <Fragment>
      <Helmet>
        <title>Nailie Dashboard | Beautist | Search</title>
      </Helmet>
      <PageHeader
        goBack={true}
        title={'Beautist search'}
        breadcrumb={PageHeader.Bread.nailistSearch}
      />
      <div className="page-container" id="nailist-page-search">
        <Spin spinning={loading}>
          <Card>
            <div className="search-section">
              <FilterForm
                onSubmit={handleSubmitFilterForm}
                initialValues={loaded ? _.pick(search, ['keyword', 'date-range', 'premium', 'status', 'role']) : {}}
              />
            </div>
            <Table
              columns={columns}
              dataSource={list}
              scroll={{ x: 1000 }}
              onChange={handleTableChange}
              pagination={{
                total: total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 10,
                current: Number(search.page) || 1,
                showSizeChanger: false
              }}
              // loading={loading}
            />
          </Card>
        </Spin>
      </div>
    </Fragment>
  );
};

NewSearchNailistPage.propTypes = {
  list: PropTypes.array,
  total: PropTypes.any,
  fetchNailistList: PropTypes.func
};

export default NewSearchNailistPage;