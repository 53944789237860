import React from 'react';
import { Avatar, Badge, Tag, Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { UserOutlined, EyeOutlined } from '@ant-design/icons';
import { splitInfor } from 'utils/common';
import { Link } from 'react-router-dom';

const columnsTemplates = {
  id: {
    title: 'ネイリストID',
    width: 120,
    dataIndex: '_id',
    key: '_id',
    fixed: 'left'
  },
  createdAt: {
    title: 'アカウント作成日',
    width: 200,
    dataIndex: '_created_at',
    render: (record) =>
      /*_.has(record) && */ moment(record).format('HH:mm YYYY-MM-DD'),
    key: '_created_at',
    defaultSortOrder: 'descend',
    sorter: true
  },
  fullName: {
    title: '姓名',
    key: 'Full-name',
    width: 220,
    render: (record) => {
      const AvatarJSX = (
        <Avatar
          icon={<UserOutlined />}
          src={_.get(record, 'avatar.thumbNail')}
          size="large"
        />
      );
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.isMark ? (
            <Badge className="premium-badge" count={'✔️'} title={'premium'}>
              {AvatarJSX}
            </Badge>
          ) : (
            AvatarJSX
          )}
          <div style={{ marginLeft: '8px', wordBreak: 'break-all', flex: 1 }}>
            {record.fullName || record.name}
          </div>
        </div>
      );
    }
  },
  username: {
    title: 'ユーザーネーム',
    dataIndex: 'username',
    key: 'username',
    width: 160,
    render: (record) => <div>{splitInfor(record)}</div>
  },
  role: {
    title: 'ユーザー分類',
    dataIndex: 'role',
    key: 'role',
    width: 120,
    render: (record) => <div>{splitInfor(record)}</div>
  },
  salonName: {
    title: 'サロン情報',
    key: 'Salon info',
    width: 375,
    render: (record) => (
      <div style={{ display: 'block' }}>
        <div style={{ marginLeft: '8px' }}>
          {_.get(record, 'profile.salonName')}
        </div>
        <div style={{ marginLeft: '8px' }}>
          {_.get(record, 'profile.salonAddress')}
        </div>
      </div>
    )
  },
  status: {
    title: 'ステータス',
    dataIndex: 'status',
    key: 'status',
    width: 160,
    render: (record) =>
      (record === 'ACTIVE' && <Tag color="green">ACTIVE</Tag>) ||
      (record === 'INACTIVE' && <Tag>INACTIVE</Tag>)
  },
  email: {
    title: 'メールアドレス',
    dataIndex: 'email',
    key: 'email',
    width: 260,
    render: (record) => <div>{splitInfor(record)}</div>
  },
  phone: {
    title: '電話番号',
    dataIndex: 'phone',
    key: 'phone',
    width: 160
  },
  action: {
    title: '',
    dataIndex: '_id',
    key: 'Action',
    width: 120,
    fixed: 'right',
    render: (record) => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {/* <Button icon={<EditOutlined />} theme="filled" /> */}
        <Link to={`/dashboard/nailist/profile/${record}`}>
          <Button type="primary" icon={<EyeOutlined />} />
        </Link>
      </div>
    )
  }
};

export default columnsTemplates;

export const defaultOrder = {
  orderBy: '_created_at',
  order: 'descend'
};