import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { EnterOutlined, DeleteOutlined, BellOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, Card, Button, Modal } from 'antd';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

import {
  getSupportForSalonUnanswered, deleteSupportTicket, setIsReadTickets
} from 'providers/SupportProvider/actions';
import {
  makeSalonSupportUnansweredTickets,
  makeSalonSupportUnansweredLoading,
  makeSalonUnansweredFilter,
  makeDeleteUnansweredTicket
} from 'providers/SupportProvider/selectors';

import columnsTemplate from './Preset/columns';
import FilterForm from './Filter/UnansweredFilterForm';

const { confirm } = Modal;

const UnansweredTickets = ({ handleEdit }) => {
  const dispatch = useDispatch();
  const fetchUnanswered = useSelector(makeSalonSupportUnansweredTickets);
  const loading = useSelector(makeSalonSupportUnansweredLoading);
  const deletedTicket = useSelector(makeDeleteUnansweredTicket);
  const search = useSelector(makeSalonUnansweredFilter);

  const [searchUnanswered, setSearchUnanswered] = useState({
    page: Number(search.page) || 1,
    limit: Number(search.limit) || 5,
    dateFrom: _get(search, 'dateFrom'),
    dateTo: _get(search, 'dateTo') || moment().endOf('month').format('YYYY-MM-DD'),
    orderBy: _get(search, 'sortField') || '_created_at',
    order: _get(search, 'direction') || 'descend',
    keyword: _get(search, 'keyword')
  });

  const [currentUnansweredPage, setCurrentUnansweredPage] = useState(
    _has(fetchUnanswered, 'page') ? Number(_get(fetchUnanswered, 'page')) : 1);
  
  useEffect(() => {
    dispatch(getSupportForSalonUnanswered({
      ...searchUnanswered,
      supportType: 'SALON_CONTACT'
    }));
  }, []);

  useEffect(() => {
    if (search.dateFrom && search.dateTo) {
      search['date-range'] = [
        moment(search.dateFrom),
        moment(search.dateTo)
      ];
    }
    setSearchUnanswered({
      ...searchUnanswered,
      ...search
    });
  }, [search]);
  
  useEffect(() => {
    if (deletedTicket) {
      dispatch(getSupportForSalonUnanswered({
        ...searchUnanswered,
        page: 1,
        supportType: 'SALON_CONTACT'
      }));
    }
  }, [deletedTicket]);

  const columns = [
    {
      title: '',
      width: 50,
      dataIndex: 'isRead',
      render: (record) => (record ? '' : <BellOutlined />),
      key: 'isRead'
    },
    columnsTemplate.question,
    columnsTemplate.serviceRole,
    columnsTemplate.salonName,
    columnsTemplate.name,
    columnsTemplate.phone,
    columnsTemplate.email,
    columnsTemplate.subType,
    columnsTemplate.url,
    columnsTemplate.staffCount,
    columnsTemplate.createdAt,
    {
      title: '',
      key: 'Action',
      width: 120,
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            icon={<EnterOutlined />}
            theme="filled"
            onClick={() => {
              dispatch(setIsReadTickets({
                ids: [ record._id ],
                search: {...searchUnanswered, supportType: 'SALON_CONTACT'}
              }));
              handleEdit({
                title: 'Reply to question',
                ticket: {...record, answer: '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;様<br />お問い合わせ頂きましてありがとうございます。<br />Nailie運営事務局</p>'},
                search: {...searchUnanswered, supportType: 'SALON_CONTACT'}
              });
            }}
          />
          <Button
            type="primary"
            danger
            theme="filled"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record._id)}
          />
        </div>
      )
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    const parsed = {
      ...searchUnanswered,
      page: pagination.current,
      limit: pagination.pageSize,
      orderBy: sorter.field,
      order: sorter.order
    };
    setCurrentUnansweredPage(Number(pagination.current));
    dispatch(getSupportForSalonUnanswered({
      ...parsed,
      supportType: 'SALON_CONTACT'
    }));
  };

  const showDeleteConfirm = (objectId) => {
    confirm({
      title: 'Are you sure ?',
      icon: <ExclamationCircleOutlined />,
      content: 'This question will be deleted permanently and cannot be recovered. Are you sure?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteSupportTicket({ objectId: objectId, type: 'SALON_CONTACT', isAnswered: false, filter: searchUnanswered }));
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };

  const filterData = (params) => {
    let filterData = {
      ...searchUnanswered,
      ...params,
      page: 1
    };
    if (!_get(params, 'date-range')) {
      filterData = _omit(filterData, ['date-range', 'dataFrom', 'dataTo']);
    }
    if (_isEmpty(_get(params, 'keyword'))) {
      filterData = _omit(filterData, ['keyword']);
    }
    if (_isEmpty(_get(params, 'subType'))) {
      filterData = _omit(filterData, ['subType']);
    }
    setCurrentUnansweredPage(1);
    setSearchUnanswered(filterData);
    dispatch(getSupportForSalonUnanswered({ ...filterData, supportType: 'SALON_CONTACT' }));
  };

  return (
    <Card bordered={false} className="styled-transparent support-for-salon-block">
      <Card bordered={false} title="Unanswered Questions">
        <div className="search-section">
          <FilterForm
            filters={['keyword', 'date-range', 'subType']}
            placeholder='FullName, Telephone, Email'
            onSubmit={filterData}
            initialValues={searchUnanswered}
          />
        </div>
      </Card>
      <Card bordered={false}>
        <Table
          columns={columns}
          dataSource={fetchUnanswered.list}
          scroll={{ x: 1000 }}
          onChange={onTableChange}
          rowClassName={(record) => (record.isRead ? 'data-row active-row' : 'data-row') }
          pagination={{
            total: fetchUnanswered.total,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSize: searchUnanswered.limit,
            current: currentUnansweredPage,
            showSizeChanger: false
          }}
          loading={loading}
        />
      </Card>
    </Card>
  );
};

export default UnansweredTickets;
