/* eslint-disable react/display-name */
import React, { Fragment, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Card, Space, Button } from 'antd';
import useTableControl from '../hooks/useTableControl';
import FilterForm from '../FilterForm';
import { fetchBookingList, resetBookingProvider } from 'providers/BookingProvider/actions';
import { BOOKING_STATUS } from 'utils/constants';
import columnsTemplate from '../../Preset/columnsTemplate';
import '../styles.scss';
import useExportBookingModalControl from '../hooks/useExportBookingModalControl';

const {
  REQUESTED,
  EXPIRED,
  CANCELED_REQUEST,
  CANCELED_RESERVATION,
  DECLINED_REQUEST,
  DECLINED_RESERVATION,
  UNDONE
} = BOOKING_STATUS;

const GeneralTab = ({ nailistId, customerId }) => {
  const [searchParams, handleTableChange, handleSubmitFilterForm] = useTableControl({});
  const [openExportPopup, Popup] = useExportBookingModalControl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBookingList({ nailistId, customerId, page: 1, perPage: 10, ...searchParams }));
  }, [searchParams]);

  useEffect(() => {
    return () => dispatch(resetBookingProvider());
  }, []);

  const list = useSelector(state => state.booking.booking.list);
  const total = useSelector(state => state.booking.booking.total);
  const isLoading = useSelector(state => state.booking.booking.isLoading);

  let columns = useMemo(() => [
    columnsTemplate.bookingId(),
    columnsTemplate.customer({ isLink: !customerId }),
    columnsTemplate.nailist(),
    columnsTemplate.serviceRole,
    columnsTemplate.coupon,
    columnsTemplate.treatmentDate,
    columnsTemplate.requestedDate,
    [REQUESTED, CANCELED_REQUEST, DECLINED_REQUEST, EXPIRED]
      .includes(searchParams.type) ? {} : columnsTemplate.approvedDate,
    searchParams.type === EXPIRED ? columnsTemplate.expiredDate : {},
    [CANCELED_REQUEST, CANCELED_RESERVATION, DECLINED_REQUEST, DECLINED_RESERVATION]
      .includes(searchParams.type) ? columnsTemplate.canceledDate : {},
    columnsTemplate.point,
    [EXPIRED].includes(searchParams.type) ? {} : columnsTemplate.totalFee,
    searchParams.type === UNDONE ? columnsTemplate.memo : {},
    searchParams.type === UNDONE ? columnsTemplate.isChecked : {},
    columnsTemplate.status,
    columnsTemplate.origin,
    columnsTemplate.paymentCardProvider,
    columnsTemplate.settlementId,
    columnsTemplate.atoneChargeId
  ], [searchParams.type]);

  if (!_.isEmpty(columns)) {
    columns = columns.reduce((prevCols, col) => {
      if (col.key) {
        if (col.key === searchParams.orderBy) {
          col.sortOrder = searchParams.order;
        } else delete col.sortOrder;
        return prevCols.concat(col);
      }
      return prevCols;
    }, []);
  }

  const handleFilterFormChange = (values) => {
    const orderParams = {};
    if (values.type !== searchParams.type) {
      orderParams.orderBy = 'bookingDate';
      orderParams.order = 'descend';
    }
    handleSubmitFilterForm({
      ...values,
      ...orderParams
    });
  };

  return (
    <Fragment>
      <div className="page-container" id="new-booking-list" style={{ display: 'block' }}>
        <Card>
          <div className="search-section" style={{ justifyContent: 'space-between' }}>
            <FilterForm
              onSubmit={handleFilterFormChange}
              initialValues={_.pick(searchParams, ['keyword', 'type', 'treatmentDate', 'origin', 'serviceRole'])}
            />
            <Space>
              {!nailistId && !customerId && <Button type="primary" onClick={() => openExportPopup()}>CSV Export</Button>}
            </Space>
          </div>
          <Table
            rowKey='_id'
            columns={columns}
            dataSource={list}
            scroll={{ x: 'max-content' }}
            onChange={handleTableChange}
            rowClassName={(record) => (record.isNew ? 'data-row active-row' : 'data-row')}
            pagination={{
              total: total,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
              current: Number(searchParams.page) || 1,
              showSizeChanger: false
            }}
            loading={isLoading}
          />
        </Card>
      </div>
      {Popup}
    </Fragment>
  );
};

GeneralTab.propTypes = {
  list: PropTypes.array,
  total: PropTypes.any,
  fetchReceiptList: PropTypes.func,
  nailistId: PropTypes.string,
  customerId: PropTypes.string
};

export default GeneralTab;