import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import PageHeader from 'components/PageHeader';

import AnnouncementForm from '../_Component/AnnouncementForm';
import { Redirect, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { isEmpty, pick } from 'lodash';

moment.locale('ja');

const EditAnnouncement = () => {
  const { objectId } = useParams();
  const announcementDetail = useSelector(state => state.announcement?.search?.list?.filter(r => r.objectId === objectId)[0]);

  if (isEmpty(announcementDetail)) {
    return <Redirect to='/dashboard/announcement'></Redirect>;
  }

  const initialValues = {
    ...pick(announcementDetail, [
      'title',
      'content',
      'types'
    ]),
    showOnRoles: announcementDetail.showOnRoles || [],
    imageId: { objectId: announcementDetail.imageId, data: announcementDetail.image },
    linkType: announcementDetail.linkTo.type || 'HTML', // linkType: 'IMAGE' || 'HTML'
    linkContentDetail: announcementDetail.linkTo.type === 'HTML' ? announcementDetail.linkTo.location : '',
    linkImage: announcementDetail.linkTo.type === 'IMAGE' ? { data: announcementDetail.linkTo.location, objectId: '1' } : '',
    linkScreen: announcementDetail.linkTo.type === 'APP_SCREEN' ? announcementDetail.linkTo.location : '',
    startEndTime: announcementDetail.startTime?.iso && announcementDetail.endTime?.iso
      ? [moment(announcementDetail.startTime?.iso), moment(announcementDetail.endTime?.iso)]
      : undefined,
    isOngoing: !!announcementDetail.isOngoing
  };

  const updateObjectId = announcementDetail.objectId;

  return (
    <div id="Edit-announcement">
      <Helmet>
        <title>Nailie Dashboard | Create Announcement</title>
      </Helmet>
      <PageHeader
        title="Edit Announcement"
        breadcrumb={PageHeader.Bread.createAnnouncement}
        goBack={true}
      />
      <div className="page-container" style={{ display: 'block' }}>
        <AnnouncementForm initialValues={initialValues} updateObjectId={updateObjectId}/>
      </div>
    </div>
  );
};

export default EditAnnouncement;