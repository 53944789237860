
module.exports = {
  BANK_TYPE: {
    PERSONAL: 'PERSONAL',
    COMPANY: 'COMPANY',
    SALON: 'SALON'
  },
  SALON_PROPERTIES:
    [
      {
        'canAction': false,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_CLEAN_MARK.png',
        'id': '135915821944847',
        'name': 'コロナ対策',
        'order': 1,
        'type': 'CLEAN_MARK'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_BUSINESS_TRIP.png',
        'id': '266461516484008',
        'name': '出張OK',
        'order': 2,
        'type': 'BUSINESS_TRIP'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_SCULP.png',
        'id': '276263224692757',
        'name': 'スカルプ',
        'order': 3,
        'type': 'SCULP'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': 'hello',
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_KIDS.png',
        'id': '015782833784909',
        'name': '子連れOK',
        'order': 4,
        'type': 'KIDS'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_PRIVATE_ROOM.png',
        'id': '031245594601546',
        'name': '個室あり',
        'order': 5,
        'type': 'PRIVATE_ROOM'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_RESONABLE.png',
        'id': '195926413740726',
        'name': 'リーズナブル',
        'order': 6,
        'type': 'RESONABLE'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_AM_10.png',
        'id': '087997137007117',
        'name': '朝10時前OK',
        'order': 7,
        'type': 'AM_10'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_PM_8.png',
        'id': '215798992870707',
        'name': '夜8時以降OK',
        'order': 8,
        'type': 'PM_8'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_PARKING.png',
        'id': '211720862481618',
        'name': '駐車場あり',
        'order': 9,
        'type': 'PARKING'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_PRIVATE_SALON.png',
        'id': '233822730116277',
        'name': 'プライベートサロン',
        'order': 10,
        'type': 'PRIVATE_SALON'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/wdbM6lxVKgjQXDBLE6Jx1Sk615mOUr12_DVD.png',
        'id': '180993147097176',
        'name': 'DVD観賞',
        'order': 11,
        'type': 'DVD'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/1evm178s7Bv1kHKiMG8S57W31O6eV5Kk_mainNail58.svg',
        'id': '227335488868079',
        'name': 'メンズネイル',
        'order': 12,
        'type': 'MEN_NAILS'
      },
      {
        'canAction': true,
        'conditions': {},
        'description': null,
        'icon': 'https://d1qyhbwogwcazp.cloudfront.net/kv6zm18rDqf8SeKFy3QuZzuS0LKP2WJu_gel58.svg',
        'id': '175859915834203',
        'name': 'パラジェル',
        'order': 13,
        'type': 'PARAGEL'
      }
    ],
  STATUS: {
    SUCCESS: '完了',
    PENDING: '未整理'
  },
  BOOKING_STATUS: {
    REQUESTED: 'REQUESTED',
    CONFIRMED: 'CONFIRMED',
    WAITING_DONE: 'WAITING_DONE',
    EXPIRED: 'EXPIRED',
    CANCELED_REQUEST: 'CANCELED_REQUEST',
    CANCELED_RESERVATION: 'CANCELED_RESERVATION',
    DECLINED_REQUEST: 'DECLINED_REQUEST',
    DECLINED_RESERVATION: 'DECLINED_RESERVATION',
    DONE: 'DONE',
    NOVISIT: 'NOVISIT',
    NAILIST_CANCELED: 'NAILIST_CANCELED',
    UNDONE: 'UNDONE',
    NAILIE_CANCELED: 'NAILIE_CANCELED'
  },
  BOOKING_ORIGIN: {
    APP: 'APP',
    WEB: 'WEB'
  },
  CHANGING_BK_STATUS: require('./changingBKStatusEnum'),
  CSV_CARD_ERROR_BOOKING_STRUCTURE: {
    objectId: 'Booking ID',
    nailist: 'ネイリスト',
    customer: 'カスタマー',
    errorDate: 'エラー日',
    bookingDate: '施術日',
    totalFee: '最終金額',
    status: 'Status',
    origin: 'Origin',
    paymentMethod: 'Payment',
    settlementId: '加盟店取引ID'
  },
  CSV_DELETED_ACCOUNT_STRUCTURE: {
    _id: 'userId',
    username: 'username',
    fullName: 'realName',
    role: 'role',
    phone: 'phoneNumber',
    email: 'email',
    deletionType: 'deletionType',
    reasonDeleteAccount: 'reason',
    deleteDate: 'deletedAt'
  },
  SALON_SHARED_TYPES: {
    'REG_A': { text: '通常-A型', shortenedText: '通常A' },
    'REG_B': { text: '通常-B型', shortenedText: '通常B' },
    'SHARED': { text: 'シェア型', shortenedText: 'シェア' },
    'FIVE_STAR': { text: 'Five Star Studio', shortenedText: 'FSS'}
  },
  USER_STATUS: {
    'ACTIVE': { value: 'ACTIVE', color: 'green' },
    'INACTIVE': { value: 'INACTIVE', color: '' },
    'PENDING': { value: 'PENDING', color: 'orange' },
    'REQUEST_DELETE': { value: 'DELETED', color: 'magenta' },
    'PERMANENT_DELETED': { value: 'FORCED DELETION', color: 'purple' }
  },
  POINT_CARD_BORDER_COLOR: {
    currentPoint: '#87CEFA',
    earnedPoint: '#1E90FF',
    usedPoint: '#4682B4'
  },
  RANK_NAME: {
    Bronze: 'ブロンズ',
    Silver: 'シルバー',
    Gold: 'ゴールド',
    Platinum: 'プラチナ',
    Diamond: 'ダイヤモンド'
  },
  APP_SCREEN_NAMES: [
    "RANKING_DETAIL", "RANKING_HELP", "SCHEDULE", "INVITATION_DETAIL", "NAILIST_INVITE_FRIEND_DETAIL", "COUPONS_LIST", "JOIN_SALON_INVITATION", "DISCONNECTED_SALON", "MY_MENU_LIST", "BOOKING_DETAIL", "LOGIN_POPUP", "BANK_INFO", "STATION_POPUP", "EDIT_SALON", "TAB_HOME", "TAB_DESIGN", "TAB_RESERVATION", "TAB_NOTIFICATION", "TAB_PROFILE", "NAILIST_INSIGHT", "PAYMENT_POPUP", "AUTO_MESSAGE_SETTING"
  ]

};
