import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import '@ant-design/compatible/assets/index.css';


const FilterForm = ({ onSubmit, initialValues }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="inline"
      onFinish={onSubmit}
      style={{ marginBottom: 8 }}
      initialValues={initialValues}
    >
      <Form.Item name='status'>
        <Select placeholder='status'>
          <Select.Option value="ACTIVE">ACTIVE</Select.Option>
          <Select.Option value="INACTIVE">INACTIVE</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name='showOnRoles'>
        <Select placeholder='ターゲット' allowClear={true}>
          <Select.Option value="NAILIST">NAILIST</Select.Option>
          <Select.Option value="CUSTOMER">CUSTOMER</Select.Option>
          <Select.Option value="EYELIST">EYELIST</Select.Option>
          <Select.Option value="NEW_CUSTOMER">NEW_CUSTOMER</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          Search
        </Button>
      </Form.Item>

    </Form>
  );
};

FilterForm.propTypes = {
  onSubmit: PropTypes.any,
  initialValues: PropTypes.object
};

export default FilterForm;
