import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import { Table, Space, Form, Select, Button, DatePicker, Typography, Avatar, Row, Col } from 'antd';
// import _ from 'lodash';
import { SearchOutlined, UserOutlined, LinkOutlined } from '@ant-design/icons';
import moment from 'moment';
import columnsTemplate from './Columns';
import { defaultPeriodId } from 'utils/common';
import ExecPayoutButton from './ExecChangePayoutButton';
import { formatYen } from 'utils/stringFormat';
import './styles.scss';
const { Option } = Select;
const { Text } = Typography;

const Content = ({
  data,
  fetchChangedPayouts,
  changedPayoutsList,
  // execChangePayout,
  execChangeAllPayout,
  closePopup
}) => {
  const [form] = Form.useForm();
  const { payoutType } = data;
  const now = moment();
  const lastPeriodDate = now.get('date') >= 16
    ? moment().startOf('M')
    : moment().add(-1, 'M').endOf('M');

  const defaultSearchPeriodId = defaultPeriodId();
  const [searchValue, setSearchValue] = useState(defaultSearchPeriodId);
  const [loading, setLoading] = useState(false);
  const [isChangedPayout, setIsChangedPayout] = useState(false);

  useEffect(() => {
    fetchChangedPayouts({
      period: searchValue,
      type: payoutType
    });
  }, [searchValue]);

  const salonDataSource = changedPayoutsList.map(obj => {
    return {
      key: _get(obj, 'salon._id', obj.nailistId) + obj.periodId,
      ...obj.salon,
      periodId: obj.periodId,
      changedAmount: obj.changedAmount,
      items: obj.items
    };
  });
  const canChangeAllPayout = salonDataSource.length > 0;
  const handleChangeAllPayout = () => {
    if (salonDataSource.length > 0) {
      setLoading(true);
      execChangeAllPayout(changedPayoutsList).then(() => {
        window.location.reload();
      })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  // Columns of expanded table
  const expandedRowNailistRender = ({ items }) => {
    const expandedColumns = [
      {
        ...columnsTemplate.nailistName,
        render: (record) => (
          <Link
            to={'/dashboard/nailist/profile/' + _get(record, 'nailistId')}
            target="_blank"
          >
            <div style={{ marginLeft: '8px' }}>
              <LinkOutlined />
              {' ' + _get(record, 'nailistUsername')}
            </div>
          </Link>
        )
      },
      columnsTemplate.nailistEmail,
      {
        ...columnsTemplate.oldAmount,
        render: (record) => (<Text >{formatYen(record)}</Text>)
      },
      {
        ...columnsTemplate.newAmount,
        render: (record) => (<Text type='warning'>{formatYen(record)}</Text>)
      }
    ];

    return <Table columns={expandedColumns} dataSource={items} pagination={false} />;
  };

  const salonColumns = [
    {
      ...columnsTemplate.salonName,
      width: 220,
      render: (record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            icon={<UserOutlined />}
            src={_get(record, 'logoUrl')}
            size="large"
          />
          <div style={{ marginLeft: '8px' }}>{_get(record, 'businessName')}</div>
        </div>
      )
    },
    columnsTemplate.salonEmail,
    {
      ...columnsTemplate.salonChangedAmount,
      render: (record) => {
        if (record > 0) {
          return (<Text type='success'> {formatYen(record)}</Text>);
        } else {
          return (<Text type='danger'>{formatYen(record)}</Text>);
        }
      }
    },
    {
      title: '',
      width: 120,
      key: 'Action',
      fixed: 'right',
      render: (record) => {
        const params = [];
        params.push({
          periodId: _get(record, 'periodId'),
          salonId: _get(record, '_id')
        });

        return (
          <ExecPayoutButton
            record={record}
            onClick={() => { setIsChangedPayout(true);}}
            params={params}
          />
        );
      }
    }
  ];

  const nailistColumns = [
    {
      ...columnsTemplate.nailistName,
      render: (record) => (
        <Link
          to={'/dashboard/nailist/profile/' + _get(record, 'nailistId')}
          target="_blank"
        >
          <div style={{ marginLeft: '8px' }}>
            <LinkOutlined />
            {' ' + _get(record, 'nailistUsername')}
          </div>
        </Link>
      )
    },
    columnsTemplate.nailistEmail,
    {
      ...columnsTemplate.oldAmount,
      render: (record) => (<Text >{formatYen(record)}</Text>)
    },
    {
      ...columnsTemplate.newAmount,
      render: (record) => (<Text type='warning'>{formatYen(record)}</Text>)
    },
    {
      title: '',
      width: 120,
      key: 'Action',
      fixed: 'right',

      render: (record) => {
        const params = [];
        params.push({
          periodId: _get(record, 'periodId'),
          nailistId: _get(record, 'nailistId')
        });
        return (
          <ExecPayoutButton
            record={record}
            onClick={() => { setIsChangedPayout(true); }}
            params={params}
          />
        );
      }
    }
  ];

  return (
    <div id="payout-changed">
      <Form
        layout="inline"
        form={form}
        className="search-bar"
        name="payoutChangedForm"
        initialValues={{
          currentDate: moment(lastPeriodDate, 'YYYY-MM'),
          period: now.get('date') > 15 ? '0' : '1'
        }}
        onFinish={(values) => {
          const search = `${values.currentDate.format('YY')}${values.currentDate.format('MM')}${values.period}`;
          setSearchValue(search);
        }}
      >
        <Form.Item name="currentDate">
          <DatePicker
            allowClear={false}
            picker="month"
          />
        </Form.Item>
        <Form.Item name="period">
          <Select
            style={{ width: 160 }}
          >
            <Option value={'0'}>First period</Option>
            <Option value={'1'}>Second period</Option>
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Search</Button>
      </Form>
      {
        payoutType === 'NAILIST' || payoutType === 'EYELIST' ?
          <Table
            loading={loading}
            columns={nailistColumns}
            dataSource={changedPayoutsList}
            scroll={{ x: true }}
            size="small"
            pagination={false}
          />
          :
          <Table
            loading={loading}
            dataSource={salonDataSource}
            columns={salonColumns}
            expandable={{ expandedRowRender: expandedRowNailistRender }}
            pagination={false}
          />
      }
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={() => {
                if (isChangedPayout) {
                  window.location.reload();
                } else {
                  closePopup();
                }
              }}> Close </Button>
              <Button type="primary" disabled={!canChangeAllPayout} onClick={() => handleChangeAllPayout()} >Change all</Button>
            </Space>
          </Col>
        </Row>
      </div>

    </div>
  );
};

Content.propTypes = {
  data: PropTypes.any,
  fetchChangedPayouts: PropTypes.func,
  changedPayoutsList: PropTypes.array,
  changePayouts: PropTypes.func,
  // execChangePayout: PropTypes.func,
  execChangeAllPayout: PropTypes.func,
  closePopup: PropTypes.func

};

export default Content;
