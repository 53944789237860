import _ from 'lodash';
import moment from 'moment';
import qs from 'querystring';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Popover, Typography, Tag, Table, Card, Button, Space } from 'antd';
import { LinkOutlined, TransactionOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';

import { formatYen } from 'utils/stringFormat';
import { getPeriodDate } from 'utils/common';

import BookingFee from 'components/BookingFee';
import PayoutStatus from 'components/Tag/PayoutStatus';
import PayoutPopup from 'components/Popup/PayoutPopup';
import PayoutCompletePopup from 'components/Popup/PayoutCompletePopup';
import PayoutChanged from 'components/Popup/PayoutChangedPopup';
import SearchBar from '../components/SearchBar';

import '../styles.scss';

const { Text } = Typography;

const renderBookingValue = ({
  allBookingsNumber,
  uncompletedBookingsNumber,
  totalBookingValue
}) => {
  return (
    <Popover
      placement="rightTop"
      title={`Total bookings: ${allBookingsNumber}`}
      content={
        <Space direction="vertical">
          <Text>
            Charged bookings:{' '}
            <Text style={{ color: 'green' }} strong>
              {allBookingsNumber - Number(uncompletedBookingsNumber)}
            </Text>
          </Text>
          <Text>
            Uncompleted bookings:{' '}
            <Text type="warning" strong>
              {uncompletedBookingsNumber}
            </Text>
          </Text>
        </Space>
      }
    >
      <Text type="warning" underline>
        {formatYen(totalBookingValue)}
      </Text>
    </Popover>
  );
};

const renderCommission = (data) => {
  return (
    <Popover
      placement="rightTop"
      content={
        !data.bookingFeePercent ? null : (
          <Space direction="vertical">
            <Text>
              New Customer (
              {Math.round(
                (data.bookingFeePercent.firstBookingFee * 10000) / 100
              )}
              %):{' '}
              <Text style={{ color: 'green' }} strong>
                {data.bookingFeePercent.firstBookingFeeAmount}
              </Text>
            </Text>
            <Text>
              Repeated Customer (
              {Math.round((data.bookingFeePercent.bookingFee * 10000) / 100)}
              %):{' '}
              <Text type="warning" strong>
                {data.bookingFeePercent.bookingFeeAmount}
              </Text>
            </Text>
          </Space>
        )
      }
    >
      <Text type="danger" underline>
        {formatYen(-Number(data.nailieComission))}
      </Text>
    </Popover>
  );
};

class NailistPayoutPage extends React.PureComponent {
  static defaultOrderBy = 'objectId';
  static defaultOrder = 'descend';
  tableRef = null;
  state = {
    selectedIds: [],
    download: 'download',
    downloadBank: 'download',
  };

  updateSearchToState = () => {
    let { pathname, replace, search } = this.props;
    let reloaderFlag = false;

    if (_.has(this.props, 'nailistInfo._id') && !_.has(search, 'nailistId')) {
      search['nailistId'] = _.get(this.props, 'nailistInfo._id');
      reloaderFlag = true;
    }

    if (!_.get(search, 'orderBy') || !_.get(search, 'order')) {
      search.orderBy = 'totalBookingValue';
      search.order = -1;
    }

    if (!_.has(search, 'date-range') || !search['date-range']) {
      const lastPeriodDate =
        moment().get('date') >= 16
          ? moment().startOf('M')
          : moment().add(-1, 'M').endOf('M');
      if (_.has(search, 'nailistId')) {
        const range = [
          '2017-01-01',
          getPeriodDate(lastPeriodDate).map((date) =>
            moment(date).format('YYYY-MM-DD')
          )[1]
        ];
        search['date-range'] = range;
      } else {
        const range = getPeriodDate(lastPeriodDate).map((date) =>
          moment(date).format('YYYY-MM-DD')
        );
        search['date-range'] = range;
      }
      reloaderFlag = true;
    }

    if (reloaderFlag) {
      replace(`${pathname}?${qs.stringify(search)}`);
      return;
    }

    search.serviceRole = this.props.serviceRole;

    this.props.fetchPayoutsList({ ...search, limit: 500 });
    this.setState({ selectedIds: [] });
  };

  handleCompleteAllPayouts = () => {
    this.props.openCompletePayoutPopup({
      filter: this.props.search,
      callback: this.updateSearchToState,
      action: 'completeAllPayouts'
    });
  };

  handleCompleteMultiPayouts = (Ids) => {
    this.props.openCompletePayoutPopup({
      filter: { payoutIds: Ids },
      callback: this.updateSearchToState,
      action: 'completePayouts'
    });
  };

  handleExportFile = (forRole) => {
    const loadingState = forRole === 'OPERATOR' ? 'download' : 'downloadBank';
    this.setState({ [loadingState]: 'loading' });
    this.props.requestPayoutFile({ ...this.props.search, forRole }).then(() => {
      this.setState({ [loadingState]: 'download' });
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    const { pathname, push, search } = this.props;
    const orderBy = sorter.field;
    const order = sorter.order;
    const parsed = {
      ...search,
      page: pagination.current,
      orderBy,
      order
    };

    push(`${pathname}?${qs.stringify(parsed)}`);
  };

  componentDidMount() {
    this.updateSearchToState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateSearchToState();
    }
  }

  render() {
    const { search, total, loading, openNailistNoBankPopup, openPayoutChangedPopup } = this.props;
    const { selectedIds, serviceRole } = this.state;
    const currentPage = _.has(search, 'page')
      ? Number(_.get(search, 'page'))
      : 1;

    const columns = [
      {
        title: 'ID',
        width: 200,
        dataIndex: '_id',
        key: '_id'
      },
      {
        title: '振り込み日',
        width: 180,
        dataIndex: 'transferDate',
        render: (record) => (
          <Text>
            {record ? moment(record).format('HH:mm YYYY-MM-DD') : ' -- '}
          </Text>
        ),
        key: 'transferDate'
      },
      _.has(this.props, 'nailistInfo._id')
        ? {}
        : {
          title: this.props.serviceRole === 'EYELIST' ? 'アイリストユーザーネーム' : 'ネイリストユーザーネーム',
          key: 'Nailist',
          dataIndex: 'nailist',
          width: 190,
          render: (record) => (
            <Link
              to={'/dashboard/nailist/profile/' + _.get(record, '_id')}
              target="_blank"
            >
              <div style={{ marginLeft: '8px' }}>
                <LinkOutlined />
                {' ' + _.get(record, 'name')}
              </div>
            </Link>
          )
        },
      {
        title: '分類',
        key: 'role',
        width: 120,
        key: 'role',
        dataIndex: 'nailist',
        render: (record) => (
          <div>{' ' + _.get(record, 'role')}</div>
        )
      },
      {
        title: '売上',
        key: 'bookingValue',
        width: 120,
        className: 'column-money',
        render: (record) => renderBookingValue(record)
      },
      {
        title: 'Nailie予約手数料',
        className: 'column-money',
        key: 'commission',
        width: 130,
        render: (record) => renderCommission(record)
      },
      {
        title: '決済手数料',
        className: 'column-money',
        key: 'settlementFee',
        dataIndex: 'settlementFee',
        width: 130,
        render: (record) => (<Text type="danger" underline>-{formatYen(record)}</Text>)
      },
      {
        title: '小計',
        className: 'column-money',
        key: 'subTotal',
        width: 120,
        render: (record) => (
          <Text>
            {formatYen(
              (_.round(record.totalBookingValue) || 0) -
              (_.round(record.nailieComission) || 0) -
              (_.round(record.settlementFee) || 0)
            )}
          </Text>
        )
      },
      {
        title: '振込手数料',
        key: 'bankFee',
        width: 120,
        className: 'column-money',
        render: ({ bankFee, _id, status }) => (
          <BookingFee
            payoutType={'NAILIST'}
            value={bankFee}
            payoutId={_id}
            disabled={status !== 'UNCOMPLETE'}
            prefix={'-¥'}
          />
        )
      },
      {
        title: '振込金額',
        dataIndex: 'transferAmount',
        key: 'transfer',
        className: 'column-money',
        width: 120,
        render: (record) => (
          <Text style={{ color: 'green' }}>{formatYen(record)}</Text>
        )
      },
      {
        title: '',
        dataIndex: '',
        key: 'fillout2',
        width: 36
      },
      {
        key: 'period',
        title: '振込サイクル',
        width: 200,
        dataIndex: 'period',
        render: (record) => (
          <Text>
            {record
              ? `${moment(record.startDate)
                .utc()
                .format('YYYY-MM-DD')} - ${moment(record.endDate)
                .utc()
                .format('YYYY-MM-DD')}`
              : ' -- '}
          </Text>
        )
      },
      {
        key: 'status',
        title: 'ステータス',
        width: 150,
        dataIndex: '',
        render: (record) =>
          record.status && (
            <PayoutStatus
              status={record.status}
              error={record.bankingInfo ? '' : 'Missing banking info'}
            />
          )
      },
      {
        title: '',
        dataIndex: '',
        key: 'fillout',
        width: 'auto'
      },
      {
        title: '',
        key: 'Action',
        width: 120,
        fixed: 'right',
        render: (record) => (
          <Button onClick={() => this.props.openPayoutPopup(record)}>
            View
          </Button>
        )
      }
    ];

    return (
      <>
        <PayoutPopup />
        <PayoutCompletePopup />
        <PayoutChanged/>
        <div className="page-container" id="payout-page-search">
          <Card bordered={false}>
            <div className="search-section">
              <SearchBar filters={['keyword', 'date-range', 'status']} />
              <div style={{ flexGrow: 1 }} />
              <Button type="link" onClick={openNailistNoBankPopup}>
                View Nailist Without Bank Info
              </Button>
              <Button type="primary" onClick={() => { openPayoutChangedPopup() }}>
                Payouts changed
              </Button>
            </div>
          </Card>
          <Card bordered={false}>
            <div className="action-section">
              <p>
                <b>Payout Management</b>
              </p>
              <div className="tool-box">
                {selectedIds && !_.isEmpty(selectedIds) ? (
                  <div>
                    <Button
                      type="primary"
                      icon={<TransactionOutlined />}
                      className="check-btn"
                      onClick={() =>
                        this.handleCompleteMultiPayouts(this.state.selectedIds)
                      }
                    >
                      Mark as Completed
                    </Button>
                    for {selectedIds.length} items
                  </div>
                ) : (
                  <div>
                    <Button
                      type="primary"
                      icon={<TransactionOutlined />}
                      className="check-btn"
                      onClick={this.handleCompleteAllPayouts}
                    >
                      Mark all as Completed
                    </Button>
                    all <Tag color="volcano">UNCOMPLETE</Tag>items take effect
                  </div>
                )}
                <div style={{ flexGrow: 1 }} />
                <Button
                  type="primary"
                  icon={<LegacyIcon type={this.state.download} />}
                  onClick={() => this.handleExportFile('OPERATOR')}
                >
                  データのエクスポート
                </Button>
                <Button
                  type="link"
                  icon={<LegacyIcon type={this.state.downloadBank} />}
                  onClick={() => this.handleExportFile('BANK')}
                >
                  銀行データのエクスポート
                </Button>
              </div>
            </div>
            <div ref={(ref) => (this.tableRef = ref)} />
            <Table
              columns={columns}
              dataSource={this.props.payoutsList}
              scroll={{ x: 1000, y: '70vh' }}
              onChange={this.onTableChange}
              size="small"
              rowSelection={{
                fixed: true,
                selections: false,
                selectedRowKeys: this.state.selectedIds,
                onSelect: (record, selected, selectedRows) => {
                  const selectedIds = selectedRows.map((row) => row._id);
                  this.setState({ selectedIds });
                },
                onSelectAll: (selected, selectedRows) => {
                  const selectedIds = selectedRows.map((row) => row._id);
                  this.setState({ selectedIds });
                },
                getCheckboxProps: (record) => ({
                  disabled:
                    record.status !== 'UNCOMPLETE' ||
                    _.isEmpty(record.bankingInfo), // Column configuration not to be checked
                  name: record._id
                })
              }}
              pagination={{
                total: total,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 500,
                current: currentPage,
                showSizeChanger: false
              }}
              loading={loading}
            />
          </Card>
        </div>
      </>
    );
  }
}

NailistPayoutPage.propTypes = {
  fetchPayoutsList: PropTypes.func.isRequired,
  payoutsList: PropTypes.array,
  location: PropTypes.any,
  pathname: PropTypes.string,
  push: PropTypes.func,
  replace: PropTypes.func,
  search: PropTypes.any,
  loading: PropTypes.bool,
  total: PropTypes.number,
  openPayoutPopup: PropTypes.func,
  openCompletePayoutPopup: PropTypes.func,
  openNailistNoBankPopup: PropTypes.func,
  requestPayoutFile: PropTypes.func
};

export default NailistPayoutPage;
