import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Select as AntSelect, Button } from 'antd';
import { Field, reduxForm } from 'redux-form';

import { TextField, Select, RangePicker } from 'components/Form';

const { Option } = AntSelect;
const { Content } = Layout;

class FilterForm extends React.PureComponent {
  render() {
    return (
      <Content style={{}}>
        <Form layout="inline" onSubmit={this.props.handleSubmit}>
          <Field
            name="keyword"
            placeholder="Search Nailist By Id, Name, ..."
            component={TextField}
            allowClear
            suffix={<SearchOutlined />}
          />

          <Field
            name="date-range"
            placeholder={['From date', 'To date']}
            usingStringFormat={true}
            component={RangePicker}
          />

          <Field name="premium" placeholder="Premium" allowClear={true} component={Select}>
            <Option value={'Yes'}>Yes</Option>
            <Option value={'No'}>No</Option>
          </Field>

          <Field name="status" placeholder="Status" allowClear={true} component={Select}>
            <Option value="ACTIVE">Active</Option>
            <Option value="INACTIVE">Inactive</Option>
          </Field>

          <Field name="role" placeholder="ユーザー分類" allowClear={true} component={Select}>
            <Option value="NAILIST">NAILIST</Option>
            <Option value="EYELIST">EYELIST</Option>
          </Field>

          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </Content>
    );
  }
}

export default reduxForm({
  form: 'nailist-filter',
  destroyOnUnmount: true,
  enableReinitialize: true
  //validate
})(FilterForm);
